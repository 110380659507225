.spinner {
  animation: spin 1s linear infinite;
}

.loader {
  animation: opacity 0.4s linear forwards;
}

.loaderCheck {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: stroke 0.8s cubic-bezier(0.65, 0, 0.1, 1) 0.5s forwards;
}

@keyframes stroke {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
