@mixin downArrow($width, $height, $color) {
  width: 0;
  height: 0;
  border-left: $width solid transparent;
  border-right: $width solid transparent;
  border-top: $height solid $color;
}

$arrowWidth: 8px;
$arrowHeight: 14px;
$pink: #ff00b4;
$lightGrey: #ededed;

.button {
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: 1rem;
    @include downArrow($arrowWidth, $arrowHeight, $pink);
  }

  &::before {
    top: -2px;
    transform: translateX($arrowWidth - (1.25 * $arrowWidth));
    @include downArrow(1.25 * $arrowWidth, 1.25 * $arrowHeight, $lightGrey);
  }
}
