.checkbox::after {
  position: absolute;
  content: '';
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  transform: rotate(45deg) scale(0.3);
  opacity: 0;
  transition: all 0.3s;
}

.checkboxChecked::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
}
