$thickness: 2px;

.closeIcon {
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - #{0.5 * $thickness});
    height: 100%;
    width: $thickness;
    background-color: #000;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

// order of declarations is important as the base and afterOpen classes never
// get unapplied by react-mdoal
.modalOverlay {
  background-color: rgba(0, 0, 0, 0);

  &.afterOpen {
    background-color: rgba(0, 0, 0, 0.6);
  }

  &.beforeClose {
    background-color: rgba(0, 0, 0, 0);
  }
}

.modal {
  transform: scale(0.9);
  opacity: 0;

  &.afterOpen {
    transform: scale(1);
    opacity: 1;
  }

  &.beforeClose {
    transform: scale(0.9);
    opacity: 0;
  }
}
