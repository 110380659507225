.radio {
  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 4px auto;
    border-radius: 50%;
  }

  &:checked:before {
    background: black;
  }
}
