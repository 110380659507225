$listHighlightColor: #f1f1f1;

.select {
  $gradient: transparent 50%, rgb(0, 0, 0) 50% calc(50% + 1px),
    transparent calc(50% + 2px);
  background-image: linear-gradient(45deg, $gradient),
    linear-gradient(-45deg, $gradient);
  background-position: right 10px top calc(1em + 2px),
    right 5px top calc(1em + 2px);
  background-size: 5px 5px;
  background-repeat: no-repeat;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  padding-right: 1rem;
}

.reactTelInput {
  position: relative;
  width: 100%;

  .formControl {
    padding-left: 4rem;
    outline: none;
    height: 100%;
    width: 100%;
  }

  .flagDropdown {
    position: absolute;
    top: 0;
    height: 100%;
    border-right: 1px solid rgb(140, 140, 140);
    background-image: linear-gradient(
      to bottom,
      #ffffff,
      rgba(229, 229, 229, 0.54) 98%
    );
  }

  .selectedFlag {
    outline: none;
    height: 100%;
    padding: 0 0 0 8px;

    .flag {
      font-size: 2rem;
      height: 100%;
    }
  }

  .selectedFlag:hover,
  .selectedFlag:focus {
    background-color: #fff;
  }

  .countryList {
    position: absolute;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: white;
    overflow-y: scroll;
    max-height: 150px;
    width: 100%;

    .flag {
      display: inline-block;
    }

    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }

    .country {
      padding: 7px 9px;
    }

    .country .dialCode {
      color: #6b6b6b;
    }

    .country:hover {
      background-color: $listHighlightColor;
    }

    .country.highlight {
      background-color: $listHighlightColor;
    }

    .countryName {
      margin-right: 6px;
    }
  }
}
